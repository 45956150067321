
import EventManager                   from '@brainscape/event-manager';
import React                          from 'react';
import Spinner                        from '_views/shared/Spinner';

import {toClassStr} from '_utils/UiHelper';

class SimpleTextButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };

    /*
      this.props:
        addClasses,
        iconClass,
        isDisabled,
        isProcessing,
        label,
        onClick,
        onFocus,
        shouldDeferBlurOnClick  // this is used to fine tune click behavior
        spinnerColor,
        customIcon,
        title,
        tooltipClasses,
        tooltipContent,
        tooltipDelay,
        tooltipPosition,
        uiTestId,
    */

    this._isMounted = false;

    this.elem = null;

    this.tooltipHover = false;
    this.tooltipTimeout = null;
  }


  /*
  ==================================================
   LIFECYCLE METHODS
  ==================================================
  */

  componentDidMount() {
    this._isMounted = true;
    this.clearTimeoutsAndIntervals();
  }

  componentWillUnmount() {
    this.clearTimeoutsAndIntervals();
    this._isMounted = false;
  }


  /*
  ==================================================
   RENDERERS
  ==================================================
  */

  render() {
    const isDisabledClass = this.props.isDisabled ? 'is-disabled' : '';
    const isProcessingClass = this.props.isProcessing ? 'is-processing' : '';
    const hasTooltipClass = this.props.tooltipContent ? 'has-tooltip' : '';
    const classes = toClassStr(['simple-text-button', isDisabledClass, isProcessingClass, hasTooltipClass, this.props.addClasses]); 
    
    return (
      <div
        className={classes}
        onClick={(e) => this.handleButtonClick(e)}
        onFocus={(e) => this.handleButtonFocus(e)}
        onMouseDown={this.handleMouseDown}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
        ref={(elem) => { this.elem = elem }} 
        title={this.props.title}
        {...(this.props.uiTestId && { 'data-ui-test-id': this.props.uiTestId })}
      >
        {this.renderIcon()}

        <span className="button-label">{this.props.label}</span>
        {this.renderSpinner()}
      </div>
    );
  }

  renderIcon() {
    if (!(this.props.iconClass || this.props.customIcon)) {
      return null;
    }

    if (this.props.customIcon) {
      return this.props.customIcon;
    }

    const classes = toClassStr(['icon', this.props.iconClass]);

    return (
      <i className={classes} />
    );
  }

  renderSpinner() {
    if (!this.props.isProcessing) {
      return null;
    }

    return (
      <Spinner 
        color={this.props.spinnerColor}
      />
    );
  }


  /*
  ==================================================
   EVENT HANDLERS
  ==================================================
  */

  handleButtonClick(e) {
    if (e) {
      e.stopPropagation();
    }

    if (this.props.isDisabled || this.props.isProcessing) {
      return false;
    }

    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  handleButtonFocus(e) {
    if (e) {
      e.stopPropagation();
    }

    if (this.props.isDisabled || this.props.isProcessing) {
      return false;
    }

    if (this.props.onFocus) {
      this.props.onFocus();
    }
  }

  handleMouseDown = (e) => {
    if (this.props.shouldDeferBlurOnClick) {
      e.preventDefault();
    }
  }

  handleMouseEnter = (e) => {
    if (this.props.tooltipContent) {

      if (this.props.tooltipDelay) {
        this.tooltipHover = true;

        this.tooltipTimeout = setTimeout(() => {
          if (this.tooltipHover) {
            this.triggerTooltipOpen();
          }
        }, this.props.tooltipDelay);

      } else {
        this.triggerTooltipOpen();
      }
    }
  }

  handleMouseLeave = () => {
    if (this.props.tooltipContent) {
      this.tooltipHover = false;
      this.triggerTooltipClose();
    }
  }


  /*
  ==================================================
   EVENT TRIGGERS
  ==================================================
  */

  triggerTooltipOpen = () => {
    if (!this._isMounted) {
      return false;
    }

    EventManager.emitEvent('tooltip:open', {
      addClasses: this.props.tooltipClasses,
      content: this.props.tooltipContent,
      elem: this.elem,
      position: this.props.tooltipPosition,
    });
  };

  triggerTooltipClose = () => {
    if (!this._isMounted) {
      return false;
    }
    
    EventManager.emitEvent('tooltip:close', {});
  };


  /*
  ==================================================
   LOCAL UTILS
  ==================================================
  */

  clearTimeoutsAndIntervals = () => {
    clearTimeout(this.tooltipTimeout);
  }
}

export default SimpleTextButton;
